import React, {useState} from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  // Tab,
  // Tabs,
  TextField,
  Typography
} from "@material-ui/core";
// import PlayArrow from "@material-ui/icons/PlayArrow";
import CalendarToday from "@material-ui/icons/CalendarToday";
// import AccessTime from "@material-ui/icons/AccessTime";
import Close from "@material-ui/icons/Close";
import GetApp from "@material-ui/icons/GetApp";

import RyanTaftAvatar from '../../assets/img/sales_training/ryan_taft_avatar.png';
import Impact88 from '../../assets/img/sales_training/impact_88_logo.png';

import useStyles from "./styles";

interface IVideo {
  id: string;
  title: string;
  description: string;
  thumbnail: string;
  videoUrl: string;
  duration: string;
  date: string;
  category: string;
  creator: {
    name: string;
    title: string;
    description: string;
    avatar: string;
    company: string;
    companyUrl: string;
    companyLogo: string;
  };
}

const SalesTraining = () => {
  const classes = useStyles();
  const [selectedVideo, setSelectedVideo] = useState<IVideo | null>(null);
  const [showConsultation, setShowConsultation] = useState(false);
  const [activeTab] = useState("all");

  const videos: IVideo[] = [
    {
      id: "1",
      title: "Best Practices for NterNow Lead Follow-up",
      description:
        "Learn proven techniques to establish trust and convert leads into buyers with effective follow-up strategies.",
      thumbnail: "/placeholder.svg?height=720&width=1280",
      videoUrl: "https://www.youtube.com/embed/bjnFacXst5w?si=Pgd57OAebPAC03zj",
      duration: "15 minutes",
      date: "March 26, 2025",
      category: "Lead Conversion",
      creator: {
        name: "Ryan Taft",
        title: "Sales Training Expert",
        description:
          "Ryan Taft is a renowned sales trainer with over 15 years of experience in the real estate and property technology industries. He specializes in lead conversion strategies and customer relationship management.",
        avatar: RyanTaftAvatar,
        company: "Impact Eighty Eight",
        companyUrl: "https://www.impacteightyeight.com/",
        companyLogo: Impact88,
      }
    },
/*    {
      id: "2",
      title: "Mastering Initial Client Conversations",
      description:
        "Master the art of first impressions and learn how to establish rapport with potential buyers in the first meeting.",
      thumbnail: "/placeholder.svg?height=720&width=1280",
      videoUrl:
        "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
      duration: "18 minutes",
      date: "March 20, 2025",
      category: "Sales Techniques",
      creator: {
        name: "Jessica Chen",
        title: "Communication Specialist",
        description:
          "Jessica Chen is an expert in communication strategies with a focus on real estate sales. She has trained over 5,000 sales professionals in effective client communication techniques.",
        avatar: "/placeholder.svg?height=200&width=200",
        company: "Speak With Impact",
        companyLogo: "/placeholder.svg?height=60&width=120"
      }
    },
    {
      id: "3",
      title: "Digital Marketing for Property Sales",
      description:
        "Learn how to leverage digital marketing channels to attract qualified leads and showcase new construction properties effectively.",
      thumbnail: "/placeholder.svg?height=720&width=1280",
      videoUrl:
        "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4",
      duration: "25 minutes",
      date: "March 15, 2025",
      category: "Marketing",
      creator: {
        name: "Michael Rodriguez",
        title: "Digital Marketing Director",
        description:
          "Michael Rodriguez specializes in digital marketing strategies for real estate developers. His campaigns have generated over $50M in new construction sales in the past year alone.",
        avatar: "/placeholder.svg?height=200&width=200",
        company: "PropTech Marketing",
        companyLogo: "/placeholder.svg?height=60&width=120"
      }
    },
    {
      id: "4",
      title: "Closing Techniques for Real Estate",
      description:
        "Learn specialized closing methods that work specifically for new construction properties and overcome last-minute objections.",
      thumbnail: "/placeholder.svg?height=720&width=1280",
      videoUrl:
        "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4",
      duration: "20 minutes",
      date: "March 10, 2025",
      category: "Sales Strategies",
      creator: {
        name: "Sarah Johnson",
        title: "Sales Director",
        description:
          "Sarah Johnson has closed over $100M in new construction sales. She specializes in high-conversion closing techniques and objection handling for premium properties.",
        avatar: "/placeholder.svg?height=200&width=200",
        company: "Elite Sales Academy",
        companyLogo: "/placeholder.svg?height=60&width=120"
      }
    }*/
  ];

  const filteredVideos =
    activeTab === "all"
      ? videos
      : videos.filter(
        video => video.category.toLowerCase() === activeTab.toLowerCase()
      );

  // const handleTabChange = (event: any, newValue: any) => setActiveTab(newValue);

  const handleSubmitConsultation = (e: any) => {
    e.preventDefault();
    alert("Consultation request submitted!");
    setShowConsultation(false);
  };

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Box className={classes.header}>
          <Typography variant="h1" className={classes.heading} gutterBottom>
            New Construction Sales Training
          </Typography>
          <Typography variant="h6" className={classes.subheading}>
            Master the art of selling new construction homes with our expert
            video training series
          </Typography>
        </Box>

        {/*<Box className={classes.tabsWrapper}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
            className={classes.TabsX}
          >
            <Tab className={classes.tabRoot} label="All Videos" value="all"/>
            <Tab
              className={classes.tabRoot}
              label="Lead Conversion"
              value="lead conversion"
            />
            <Tab
              className={classes.tabRoot}
              label="Sales Techniques"
              value="sales techniques"
            />
            <Tab
              className={classes.tabRoot}
              label="Marketing"
              value="marketing"
            />
            <Tab
              className={classes.tabRoot}
              label="Sales Strategies"
              value="sales strategies"
            />
          </Tabs>
        </Box>*/}

        <Grid container spacing={4}>
          {filteredVideos.map(video => (
            <Grid item xs={12} md={6} key={video.id}>
              <Card className={classes.card}>
                <Box
                  className={classes.thumbnailWrapper}
                  onClick={() => setSelectedVideo(video)}
                >
                  <CardMedia
                    component="img"
                    image={`https://img.youtube.com/vi/bjnFacXst5w/hqdefault.jpg`}
                    alt={video.title}
                    className={classes.thumbnail}
                  />
                  {/*<iframe
                    className={classes.thumbnail}
                    src="https://www.youtube.com/embed/bjnFacXst5w?si=Pgd57OAebPAC03zj"
                    title="Ryan Taft: Best Practices for Lead Follow-up"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen></iframe>*/}
                  {/*<Box className={classes.overlay}>
                    <Box className={classes.playButton}>
                      <PlayArrow style={{color: "white", fontSize: 32}}/>
                    </Box>
                  </Box>*/}
                  <Box className={classes.metadataOverlay}>
                    <Chip label={video.category} color="primary" size="small"/>
                    {/*<Box className={classes.durationChip}>
                      <AccessTime style={{fontSize: 14}}/>
                      <Typography variant="body2">{video.duration}</Typography>
                    </Box>*/}
                  </Box>
                </Box>

                <CardContent className={classes.cardContent}>
                  <Box className={classes.creatorInfo}>
                    <Avatar
                      src={video.creator.avatar}
                      alt={video.creator.name}
                      className={classes.creatorAvatar}
                    />
                    <Box>
                      <Typography variant="h6" className={classes.videoTitle}>
                        {video.title}
                      </Typography>
                      <Box className={classes.creatorText}>
                        <Typography variant="subtitle2" color="primary">
                          {video.creator.name}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          • {video.date}
                        </Typography>
                      </Box>
                      <Typography variant="body2" color="textSecondary">
                        {video.creator.title} at{" "}
                        <a
                          href={video.creator.companyUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{color: "inherit", textDecoration: "underline"}}
                        >
                          {video.creator.company}
                        </a>
                      </Typography>
                    </Box>
                  </Box>
                  <Typography
                    variant="body2"
                    className={classes.videoDescription}
                  >
                    {video.description}
                  </Typography>
                  <Box className={classes.actionButtons}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setSelectedVideo(video)}
                      className={classes.watchVideoButton}
                    >
                      Watch Video
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<CalendarToday/>}
                      onClick={() => setShowConsultation(true)}
                      className={classes.ScheduleButton}
                    >
                      Schedule Consultation
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Video Dialog */}
      <Dialog
        open={!!selectedVideo}
        onClose={() => setSelectedVideo(null)}
        maxWidth="lg"
        fullWidth
      >
        {selectedVideo && (
          <>
            <DialogContent style={{padding: 0}}>
              <Grid container>
                <Grid item xs={12} md={8}>
                  <Box style={{position: "relative", paddingTop: "56.25%"}}>
                    {/*<video
                      src={selectedVideo.videoUrl}
                      controls
                      autoPlay
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%"
                      }}
                    />*/}
                    <iframe
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%"
                      }}
                      src="https://www.youtube.com/embed/bjnFacXst5w?si=Pgd57OAebPAC03zj&autoplay=1&rel=0"
                      title="Ryan Taft: Best Practices for Lead Follow-up"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen></iframe>
                  </Box>
                  <Box
                    padding={3}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px"
                    }}
                  >
                    <Typography variant="h5">{selectedVideo.title}</Typography>
                    <Box
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "10px"
                      }}
                    >
                      <Chip
                        label={selectedVideo.category}
                        color="primary"
                        size="small"
                      />
                    </Box>
                    {/*<div className="flex items-center gap-2 mb-4">
                      <Badge className="bg-orange-500"></Badge>
                      <span className="text-sm text-slate-500">•</span>
                      <div className="flex items-center text-sm text-slate-500">
                      <Clock className="w-3 h-3 mr-1" />
                      {selectedVideo.duration}
                      </div>
                      <span className="text-sm text-slate-500">•</span>
                      <span className="text-sm text-slate-500">{selectedVideo.date}</span>
                      </div> */}
                    <Typography variant="body2" color="textSecondary">
                      {selectedVideo.description}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Box
                    padding={3}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyItems: "center",
                      gap: "10px"
                    }}
                  >
                    <Avatar
                      src={selectedVideo.creator.avatar}
                      alt={selectedVideo.creator.name}
                      style={{
                        width: 96,
                        height: 96,
                        border: "4px solid #f97316",
                        marginBottom: 16
                      }}
                    />
                    <Typography variant="h6">
                      {selectedVideo.creator.name}
                    </Typography>
                    <Typography variant="subtitle1" color="primary">
                      {selectedVideo.creator.title}
                    </Typography>
                    <a
                      href={selectedVideo.creator.companyUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={selectedVideo.creator.companyLogo}
                        alt={selectedVideo.creator.company}
                        style={{ margin: "16px auto", height: 32 }}
                      />
                    </a>

                    <Typography variant="body2" color="textSecondary">
                      {selectedVideo.creator.description}
                    </Typography>
                    <Box
                      display="flex"
                      flexDirection="column"
                      marginTop={2}
                      style={{
                        width: "100%"
                      }}
                    >
                      <Box mb={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<GetApp/>}
                          className={classes.modalDownloadButton}
                        >
                          Download Resources
                        </Button>
                      </Box>
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<CalendarToday/>}
                        className={classes.modalScheduleButton}
                        onClick={() => {
                          setSelectedVideo(null);
                          setShowConsultation(true);
                        }}
                      >
                        Schedule Consultation
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <IconButton onClick={() => setSelectedVideo(null)}>
                <Close/>
              </IconButton>
            </DialogActions>
          </>
        )}
      </Dialog>

      {/* Consultation Form Dialog */}
      <Dialog
        open={showConsultation}
        onClose={() => setShowConsultation(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Schedule a Consultation</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmitConsultation}>
            <TextField fullWidth label="Full Name" required margin="normal"/>
            <TextField fullWidth label="Email" required margin="normal"/>
            <TextField
              fullWidth
              label="Phone Number"
              required
              margin="normal"
            />
            <TextField fullWidth label="Company" required margin="normal"/>
            <TextField
              fullWidth
              type="date"
              label="Preferred Date"
              InputLabelProps={{shrink: true}}
              required
              margin="normal"
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Preferred Time</InputLabel>
              <Select defaultValue="">
                <MenuItem value="morning">Morning (9AM - 12PM)</MenuItem>
                <MenuItem value="afternoon">Afternoon (1PM - 5PM)</MenuItem>
                <MenuItem value="evening">Evening (6PM - 8PM)</MenuItem>
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="Additional Info"
              multiline
              rows={3}
              margin="normal"
            />
            <DialogActions>
              <Button onClick={() => setShowConsultation(false)}>Cancel</Button>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SalesTraining;
