import {makeStyles, Theme, createStyles} from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      minHeight: "100vh",
      backgroundColor: "#f8fafc"
    },
    container: {
      paddingTop: "20px",
      paddingBottom: "20px"
    },
    header: {
      marginBottom: "20px",
      textAlign: "center"
    },
    heading: {
      fontWeight: 700,
      fontSize: "2.5rem",
      lineHeight: 1.2
    },
    subheading: {
      color: "#64748b",
      maxWidth: 800,
      margin: "0 auto"
    },
    tabsWrapper: {
      marginBottom: "10px",
      display: "flex",
      justifyContent: "center",
      backgroundColor: "#f8fafc"
    },
    card: {
      height: "100%",
      display: "flex",
      flexDirection: "column"
    },
    thumbnailWrapper: {
      position: "relative",
      cursor: "pointer",
      "&:hover $overlay": {
        backgroundColor: "rgba(0, 0, 0, 0.5)"
      },
      "&:hover $playButton": {
        transform: "scale(1.1)"
      }
    },
    thumbnail: {
      height: 220,
      objectFit: "cover",
      width: "100%"
    },
    overlay: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "background-color 0.3s ease"
    },
    playButton: {
      width: 64,
      height: 64,
      borderRadius: "50%",
      backgroundColor: "#f97316",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "transform 0.3s ease",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)"
    },
    metadataOverlay: {
      position: "absolute",
      top: 16,
      left: 16,
      right: 16,
      display: "flex",
      justifyContent: "space-between",
      zIndex: 1
    },
    durationChip: {
      display: "flex",
      alignItems: "center",
      gap: 8,
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      color: "#fff",
      padding: "4px 12px",
      borderRadius: 16,
      fontSize: 14
    },
    cardContent: {
      padding: "14px",
      display: "flex",
      flexDirection: "column",
      flexGrow: 1
    },
    creatorInfo: {
      display: "flex",
      gap: "16px",
      marginBottom: "10px"
    },
    creatorAvatar: {
      width: 48,
      height: 48,
      border: "2px solid #f97316"
    },
    creatorText: {
      display: "flex",
      alignItems: "center",
      gap: "2px",
      marginBottom: "16px"
    },
    videoTitle: {
      fontWeight: 600
    },
    videoDescription: {
      color: "#64748b",
      marginBottom: "16px"
    },
    actionButtons: {
      marginTop: "16px",
      marginRight: 5,
      display: "flex",
      gap: "15px"
    },
    watchVideoButton: {
      display: "flex",
      width: "50%",
      justifyItems: "center",
      alignItems: "center",
      backgroundColor: "#ff6900",
      color: "white",
      borderRadius: "5px"
    },
    modalDownloadButton: {
      display: "flex",
      width: "100%",
      justifyItems: "center",
      alignItems: "center",
      backgroundColor: "#ff6900",
      color: "white",
      borderRadius: "5px"
    },
    ScheduleButton: {
      display: "flex",
      width: "50%",
      justifyItems: "center",
      alignItems: "center",
      backgroundColor: "white",
      color: "#ff6900",
      border: "1px solid #ff6900",
      borderRadius: "5px"
    },
    modalScheduleButton: {
      display: "flex",
      width: "100%",
      justifyItems: "center",
      alignItems: "center",
      backgroundColor: "white",
      color: "#ff6900",
      border: "1px solid #ff6900",
      borderRadius: "5px"
    },
    TabsX: {
      backgroundColor: "#f1f5f9",
      borderRadius: "8px",
      padding: 8
    },

    tabRoot: {
      textTransform: "none",
      fontWeight: 500,
      color: "black", // Inactive color
      "&.Mui-selected": {
        backgroundColor: "white",
        fontWeight: 600,
        display: "flex",
        justifyItems: "center",
        alignItems: "center",
        borderRadius: "5px"
      }
    }

    // activeTab: {}
  });

const useStyles = makeStyles(styles);

export default useStyles;
